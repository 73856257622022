<template>
	<div class="results">
		<div class="row">
			<div class="col-12 mb-4">
				<h1 class="page-titles mt-4">{{ $t('pages.results.title') }}</h1>
			</div>
		</div>
		<div class="row" v-if="!isPeriodsLoading && !isResultsLoading">
			<div class="col-12 results-form-container py-4">
				<router-link :to="`/${$i18n.locale}/total-cashback`" class="select-form-styles results-form-container_total-cashback pulse-animation"
					>{{ $t('pages.results.totalCashback') }}
					<span class="color-red"> {{ results.currencyCode === 'GBP' ? '£' : '' }} {{ results.totalCashback }} {{ results.currencyCode === 'EUR' ? '€' : '' }} </span></router-link
				>
				<select class="select-form-styles results-form-container_period-form shadow-style" v-model="selectedPeriod" @change="getAllResults">
					<option v-for="period in periods" :value="period.id" :key="period.id"> {{ $t('pages.results.billingDate') }} {{ period.name }}</option>
				</select>
			</div>
		</div>
		<div v-if="isResultsLoading">
			<Preloader />
		</div>
		<template v-if="!isResultsLoading">
			<div v-for="plan in results.plans" :key="plan.planId">
				<ResultsProgressbar :data="plan" :currencyCode="results.currencyCode" class="mt-3" />
			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Preloader from './../components/Preloader.vue';
import ResultsProgressbar from './../components/ResultsProgressbar.vue';

export default {
	name: 'Results',
	components: {
		Preloader,
		ResultsProgressbar,
	},
	data() {
		return {
			selectedPeriod: null,
			isResultsLoading: true,
			isPeriodsLoading: true,
		};
	},
	computed: {
		...mapState({
			periods: (state) => state.period.periods,
			results: (state) => state.results.result,
		}),
	},
	methods: {
		...mapActions('period', {
			getPeriods: 'getPeriods',
		}),
		...mapActions('results', {
			getResults: 'getResults',
		}),
		async getAllResults() {
			this.isResultsLoading = true;
			await this.getResults(this.selectedPeriod);
			this.isResultsLoading = false;
		},
		thousands_separators(num) {
			if (num != null) {
				let num_parts = num.toString().split('.');
				num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				return num_parts.join('.');
			}
			if (num == null) return;
		},
		translateMonths(monthName) {
			if (this.$i18n.locale == 'en') {
				switch (monthName) {
					case 'Styczeń 2023':
						return 'January 2023';
					case 'Luty 2023':
						return 'February 2023';
					case 'Marzec 2023':
						return 'March 2023';
					case 'Kwiecień 2023':
						return 'April 2023';
					case 'Maj 2023':
						return 'May 2023';
					case 'Czerwiec 2023':
						return 'June 2023';
					case 'Lipiec 2023':
						return 'July 2023';
					case 'Sierpień 2023':
						return 'August 2023';
					case 'Wrzesień 2023':
						return 'September 2023';
					case 'Październik 2023':
						return 'October 2023';
					case 'Listopad 2023':
						return 'November 2023';
					case 'Grudzień 2023':
						return 'December 2023';
					default:
						return '';
				}
			}
			if (this.$i18n.locale == 'pl') return monthName;
		},
	},
	async created() {
		try {
			await this.getPeriods();
			const activePeriod = this.periods.find((period) => period.isActive);
			this.selectedPeriod = activePeriod.id ? activePeriod.id : null;
			this.isPeriodsLoading = false;
			await this.getAllResults();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>
