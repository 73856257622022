<template>
	<div class="row">
		<div class="col-xl-4 progressbar" v-for="(item, index) in data.results" :key="item.name">
			<div class="progressbar--img" :style="item.percent >= 100 ? '' : 'margin-left: -20px;'">
				<div class="target-value">
					<span class="target-value--currencyCode">{{ currencyCode === 'GBP' ? '£' : '' }}</span
					>{{ item.target }}<span class="target-value--currencyCode">{{ currencyCode === 'EUR' ? '€' : '' }}</span>
				</div>
				<img
					:src="require(`@/assets/images/progressbar/safes/${item.name}${item.percent >= 100 ? '-open' : ''}.png`)"
					:srcset="
						`${require(`@/assets/images/progressbar/safes/${item.name}${item.percent >= 100 ? '-open' : ''}.png`)} 1x, ${require(`@/assets/images/progressbar/safes/${item.name}${
							item.percent >= 100 ? '-open' : ''
						}@2x.png`)} 2x`
					"
				/>
			</div>
			<div class="progressbar--bar">
				<div class="progress">
					<div
						class="progress-bar"
						:class="item.percent < 100 ? 'bg-red' : 'bg-green'"
						role="progressbar"
						:style="`width: ${item.percent}%; ${item.percent >= 100 ? 'border-radius:20px 0px 0px 20px !important;' : ''}`"
						:aria-valuenow="item.percent"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="progressbar-square" :class="`progressbar-mark`">
					<div class="percent">{{ item.cashback }}%</div>
				</div>
			</div>
			<div class="progressbar--tooltip">
				<div class="custom-tooltip" v-if="item.percent < 100 && index === 0">
					<div class="custom-tooltip_triangle" :class="item.percent < 100 ? 'custom-tooltip_triangle-red' : 'custom-tooltip_triangle-green'"></div>
					<div class="custom-tooltip_container">
						<div class="custom-tooltip_container--top" :class="item.percent < 100 ? 'custom-tooltip_container--top-red' : 'custom-tooltip_container--top-green'">
							{{ currencyCode === 'GBP' ? '£' : '' }} {{ item.value }} {{ currencyCode === 'EUR' ? '€' : '' }}
						</div>
						<div class="custom-tooltip_container--bottom">
							{{ $t('pages.results.resultsProgressbar.missing') }} {{ currencyCode === 'GBP' ? '£' : '' }} {{ item.missing }} {{ currencyCode === 'EUR' ? '€' : '' }}
						</div>
					</div>
				</div>
				<div class="custom-tooltip" v-if="item.percent >= 0 && item.percent < 100 && index === 1 && data.results[0].percent === 100">
					<div class="custom-tooltip_triangle" :class="item.percent < 100 ? 'custom-tooltip_triangle-red' : 'custom-tooltip_triangle-green'"></div>
					<div class="custom-tooltip_container">
						<div class="custom-tooltip_container--top" :class="item.percent < 100 ? 'custom-tooltip_container--top-red' : 'custom-tooltip_container--top-green'">
							{{ currencyCode === 'GBP' ? '£' : '' }} {{ item.value }} {{ currencyCode === 'EUR' ? '€' : '' }}
						</div>
						<div class="custom-tooltip_container--bottom">
							{{ $t('pages.results.resultsProgressbar.missing') }} {{ currencyCode === 'GBP' ? '£' : '' }} {{ item.missing }} {{ currencyCode === 'EUR' ? '€' : '' }}
						</div>
					</div>
				</div>
				<div class="custom-tooltip" v-if="item.percent >= 0 && index === 2 && data.results[1].percent === 100">
					<div class="custom-tooltip_triangle" :class="item.percent < 100 ? 'custom-tooltip_triangle-red' : 'custom-tooltip_triangle-green'"></div>
					<div class="custom-tooltip_container">
						<div class="custom-tooltip_container--top" :class="item.percent < 100 ? 'custom-tooltip_container--top-red' : 'custom-tooltip_container--top-green'">
							{{ currencyCode === 'GBP' ? '£' : '' }} {{ item.value }} {{ currencyCode === 'EUR' ? '€' : '' }}
						</div>
						<div class="custom-tooltip_container--bottom">
							<span v-if="item.missing > 0"
								>{{ $t('pages.results.resultsProgressbar.missing') }} {{ currencyCode === 'GBP' ? '£' : '' }} {{ item.missing }} {{ currencyCode === 'EUR' ? '€' : '' }}</span
							>
						</div>
					</div>
				</div>
				<div class="sky-is-the-limit w-100 text-right">
					<img src="@/assets/progressbar/sky-is-the-limit.png" srcset="@/assets/progressbar/sky-is-the-limit.png 1x, @/assets/progressbar/sky-is-the-limit@2x.png 2x" alt v-if="index === 2" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ResultsProgressbar',
	props: {
		currencyCode: String,
		data: Object,
	},
	data() {
		return {};
	},
};
</script>
<style lang="scss" scoped>
.progress {
	height: 54px;
	border-radius: 40px 0px 0px 40px;
	width: 100%;
	background-color: #002e6a;
	.progress-bar {
		border-radius: 40px !important;
	}
}
.progressbar {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&--img {
		position: relative;
		margin-bottom: 20px;
		.target-value {
			position: absolute;
			top: 23px;
			left: 5px;
			font-weight: bold;
			font-size: 19px;
			color: #de0e0e;
			width: 67px;
			text-align: center;
			&--currencyCode {
				font-size: 12px;
			}
		}
	}
	&--bar {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 78px;
		width: 100%;
		padding-right: 65px;

		.progressbar-mark {
			color: #de0e0e;
			font-weight: bold;
			position: absolute;
			top: 3px;
			right: 13px;
			width: 78px;
			height: 78px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background-image: url('~@/assets/progressbar/marks/mark.png');
			padding-bottom: 8px;
			font-size: 20px;
		}
	}
	&--tooltip {
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.custom-tooltip {
			width: 100%;
			max-width: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			&_triangle {
				width: 0;
				height: 0;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;

				&-green {
					border-bottom: 20px solid #4ba200;
				}
				&-red {
					border-bottom: 20px solid #de0e0e;
				}
			}
			&_container {
				width: 100%;
				color: #fff;
				&--top {
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 25px;
					font-weight: bold;
					border-radius: 10px 10px 0px 0px;
					&-green {
						background-color: #4ba200;
					}
					&-red {
						background-color: #de0e0e;
					}
				}
				&--bottom {
					background-color: #9a9a9a;
					font-size: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: bold;
					height: 20px;
					border-radius: 0px 0px 10px 10px;
				}
			}
		}
	}
}

.bg-red {
	background-color: #de0e0e !important;
}
.bg-green {
	background-color: #4ba200 !important;
}
</style>
